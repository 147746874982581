// CSS Imports!
// import 'css/application';
import 'bootstrap';
import '../../css/application';

// JS Imports;
require("@rails/ujs").start()
require("turbolinks").start()
require("channels")
require("jquery")

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

require("datatables.net-bs4"); //(window, $);
require("datatables.net-responsive-bs4"); //(window, $);
require("datatables.net-buttons-bs4"); //(window, $);
require("datatables.net-select-bs4"); //(window, $);

require("datatables.net-bs4/css/dataTables.bootstrap4.css");
require("datatables.net-responsive-bs4/css/responsive.bootstrap4.css");
require("datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css");
require("datatables.net-select-bs4/css/select.bootstrap4.css");

/////// TO install javascript libraries!

// React


// Papa Parse
var Papa = require('papaparse');
window.Papa = Papa;

// Plotly
var Plotly = require('plotly.js-dist');
window.Plotly = Plotly;

// ThreeJS
// THREE = require('lib/three.module');
import * as THREE from '../lib/three.module';
window.THREE = THREE;

// DAT GUI
import * as dat from '../lib/dat.gui.module';
window.dat = dat;

//  
var BHS3D = require('../lib/BHS3D');
window.BHS3D = BHS3D;

var SQLParser = require('../lib/SQLParser');
window.SQLParser = SQLParser;



import { Lut } from "../lib/Lut";
window.Lut = Lut;

import { BufferGeometryUtils } from "../lib/BufferGeometryUtils";
window.BufferGeometryUtils = BufferGeometryUtils;

import { DragControls } from "../lib/DragControls";
window.DragControls = DragControls;

import { OrbitControls } from "../lib/OrbitControls";
window.OrbitControls = OrbitControls;

import { LineMaterial } from "../lib/LineMaterial";
window.LineMaterial = LineMaterial;

import { LineSegments2 } from "../lib/LineSegments2";
window.LineSegments2 = LineSegments2;

import { LineSegmentsGeometry } from "../lib/LineSegmentsGeometry";
window.LineSegmentsGeometry = LineSegmentsGeometry;

import { LineGeometry } from "../lib/LineGeometry";
window.LineGeometry = LineGeometry;

import { STLLoader } from "../lib/STLLoader";
window.STLLoader = STLLoader;

import { Detector } from "../lib/Detector";
window.Detector = Detector;

import { Delaunator } from "../lib/delaunator";
window.Delaunator = Delaunator;

import { ConvexGeometry, ConvexBufferGeometry } from "../lib/ConvexGeometry";
window.ConvexBufferGeometry = ConvexBufferGeometry;
window.ConvexGeometry = ConvexGeometry;

import { Earcut } from "../lib/Earcut";
window.Earcut = Earcut;

var triangulate = require("delaunay-triangulate");
window.triangulate = triangulate;

var alphaShape = require("alpha-shape")
window.alphaShape = alphaShape;

var alphaComplex = require('alpha-complex');
window.alphaComplex = alphaComplex;

import { Line2 } from "../lib/Line2";
window.Line2 = Line2;

import Stats from "../lib/stats.module";
window.Stats = Stats;

import jexcel from "jexcel";
window.jexcel = jexcel;

import PptxGenJS from "pptxgenjs";
window.PptxGenJS = PptxGenJS;

import { SVD } from 'svd-js'
window.SVD = SVD;


// import {
//     Matrix,
//     EigenvalueDecomposition
// } from 'ml-matrix';
// window.Matrix = Matrix;
// window.EigenvalueDecomposition = EigenvalueDecomposition;

// import { Matrix } from '@rayyamhk/matrix';
var Matrix = require('@rayyamhk/matrix');
window.Matrix = Matrix;

import { Eigen } from "../lib/Eigen";
window.Eigen = Eigen;

// var matrixEig = require('matrix-eig');
// window.matrixEig = matrixEig;

var eig = require('eigen')
window.eig = eig;

require('webpack-jquery-ui');
require('webpack-jquery-ui/css');

var JSONTreeView = require('json-tree-view');
window.JSONTreeView = JSONTreeView;


import DomJsonTree from 'dom-json-tree';
window.DomJsonTree = DomJsonTree;




// Tocify
import * as tocify from '../lib/jquery.tocify';
window.tocify = tocify;

// Date Range picker
import datepicker from 'js-datepicker'
window.datepicker = datepicker;

// Select 2
import 'select2'

// Client Validations;
import '@client-side-validations/client-side-validations'


//import {} from "<%= File.join(Gem.loaded_specs['nested_form_fields'].full_gem_path, 'lib/assets/javascripts/nested_form_fields.js' ) %>";

import {} from "../nested_form_fields";

import 'jquery-extendext';
import 'dot';
import 'jQuery-QueryBuilder';

import moment from 'moment'
window.moment = moment

import JSZip from "jszip";
window.JSZip = JSZip; // Make it globally available

import { saveAs } from "file-saver";
window.saveAs = saveAs;

import { create, all } from 'mathjs'
const config = {};
window.math = create(all, config);

// Custom JS
setTimeout(function() {
    $('#notice').fadeOut('slow');
}, 1000); // <-- time in milliseconds


setTimeout(function() {
    $('#alert').fadeOut('slow');
}, 1000); // <-- time in milliseconds

$(document).on('shown.bs.modal', function() {
    $('form[data-client-side-validations]').enableClientSideValidations();
});

$(document).on('turbolinks:load', function() {
    $(".alert.alert-success").fadeOut(5000);
    $(".alert.alert-danger").fadeOut(5000);
});

String.prototype.lpad = function(padString, length) {
    var str = this;
    while (str.length < length)
        str = padString + str;
    return str;
}


Array.prototype.min = function() {
    let min = Infinity;
    for (let i = 0; i < this.length; i++) {
        min = Math.min(min, this[i]);
    }
    return min;
}

Array.prototype.max = function() {
    let max = -Infinity;
    for (let i = 0; i < this.length; i++) {
        max = Math.max(max, this[i]);
    }
    return max;
};

Array.prototype.unique = function() {
    return Array.from(new Set(this));
}

Array.prototype.last = function() {
    return this[this.length - 1];
}

Array.prototype.contains = function(v) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] === v) return true;
    }
    return false;
};

Array.prototype.mean = function() {
    var sum = 0;
    for (var i = 0; i < this.length; i++) {
        sum += this[i];
    }
    return sum / this.length;
}

Array.prototype.average = function() {
    return this.reduce(function(a, b) { return a + b; }) / this.length;
};


Array.prototype.asc = function() {
    return this.sort((a, b) => a - b);
}

Array.prototype.quantile = function(q) {
    const sorted = this.asc();
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};


String.prototype.format = function() {
    var str = this;
    for (var i = 0; i < arguments.length; i++) {
        str = str.replace('{' + i + '}', arguments[i]);
    }
    return str;
};

dat.GUI.prototype.removeFolder = function(name) {
    // console.log("this", this);
    var folder = this.__folders[name];
    if (!folder) {
        return;
    }
    folder.close();
    this.__ul.removeChild(folder.domElement.parentNode);
    delete this.__folders[name];
}


dat.GUI.prototype.checkFolder = function(name) {
    var folder = this.__folders[name];
    if (!folder)
        return false;
    else
        return true;
}